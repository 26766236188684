import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import FeedComponent from '../components/FeedComponent'
import { useDispatch, useSelector } from 'react-redux'
import { saveUser } from '../reducers/user'
import GroupTabsWrapper from '../components/groups/GroupTabsWrapper'
import { backendDomain } from '../constants'

const Feed = ({ groupId }) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Feed: User Already logged in");
            dispatch(saveUser(storedUser));
        }
    }, [dispatch]);

    return (
        <>
            <Header />
            <GroupTabsWrapper>
                <FeedComponent 
                    user={user} 
                    groupId={groupId === 'global' ? null : groupId}
                />
            </GroupTabsWrapper>
        </>
    )
}

export default Feed
import React from 'react';
import { Box, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

const GroupEmptyState = ({ type }) => (
  <Box sx={{ textAlign: 'center', py: 4 }}>
    <GroupIcon sx={{ fontSize: 48, color: 'grey.400', mb: 2 }} />
    <Typography variant="h6" color="text.secondary">
      {type === 'feed' 
        ? 'No posts in this group yet. Be the first to share!' 
        : 'No leaderboard data available for this group yet.'}
    </Typography>
  </Box>
);

export default GroupEmptyState; 
import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  Button, 
  Box, 
  CircularProgress,
  Divider
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupByInviteCode, joinGroup, getUserGroups } from '../utils/groupUtils';
import { setUserGroups, saveUser, setActiveGroup } from '../reducers/user';
import Header from '../components/Header';
import { backendDomain } from '../constants';
import { Helmet } from 'react-helmet-async';

const getMetaDescription = (memberCount) => {
  if (!memberCount || memberCount < 2) {
    return "🔥Challenge friends, build streaks, climb leaderboards! The social Wordle experience you have been waiting for 🏆";
  }
  return `👫 ${memberCount} members are waiting for you!`;
};

const GroupInvitePage = () => {
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const [groupPreview, setGroupPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [joining, setJoining] = useState(false);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const data = await getGroupByInviteCode(inviteCode, user.token);
        if (data && data.name && data.memberCount) {
          setGroupPreview(data);
        } else {
          setError("Invalid group data received.");
        }
      } catch (error) {
        setError(error.message || 'Failed to load group');
      } finally {
        setLoading(false);
      }
    };

    if (inviteCode) {
      fetchGroup();
    }
  }, [inviteCode, user.token]);

  const handleGoogleLogin = () => {
    window.location.href = `${backendDomain}/auth/google`;
    sessionStorage.setItem('pendingGroupInvite', inviteCode);
  };

  const handleJoin = async () => {
    if (!user._id) {
      sessionStorage.setItem('pendingGroupInvite', inviteCode);
      navigate('/auth/callback');
      return;
    }

    setJoining(true);
    try {
      const joinResponse = await joinGroup(inviteCode, user.token);
      const updatedGroups = await getUserGroups(user.token);
      dispatch(setUserGroups(updatedGroups));
      
      dispatch(setActiveGroup(joinResponse._id));
      
      navigate('/feed');
    } catch (error) {
      setError(error.message || 'Failed to join group');
      setJoining(false);
    }
  };

  const metaDescription = getMetaDescription(groupPreview?.memberCount);
  const pageTitle = `👋 Join ${groupPreview?.name} on Social Wordle`;

  if (loading) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>
        <Header />
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>
        <Header />
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <Typography color="error">{error}</Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <Header />
      <Container 
        maxWidth="sm" 
        sx={{ 
          mt: 2, 
          mb: 4, 
          px: { xs: 2, sm: 3 },
          maxWidth: '480px !important'
        }}
      >
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3,
            borderRadius: 2,
            backgroundColor: '#ffffff'
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <img src="/assets/social-wordle.png" alt="Social Wordle" style={{ width: '200px', height: 'auto' }} />
            
            <Typography variant="h6" gutterBottom sx={{ fontSize: '1.25rem', color: 'primary.main' }}>
              👋 Join the group
            </Typography>

            <Typography variant="h3" gutterBottom sx={{ fontSize: '2rem' }}>
              {groupPreview?.name}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ my: 3, textAlign: 'center' }}>
            <Typography variant="h8" color="text.secondary">
              {metaDescription}
            </Typography>
          </Box>

          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={user._id ? handleJoin : handleGoogleLogin}
            disabled={joining}
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.rem'
            }}
          >
            {joining ? (
              <CircularProgress size={24} color="inherit" />
            ) : user._id ? (
              'Join Group'
            ) : (
              'Login with Google to Join'
            )}
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default GroupInvitePage; 
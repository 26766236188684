import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useGroups } from '../../hooks/useGroups';
import AddIcon from '@mui/icons-material/Add';
import PublicIcon from '@mui/icons-material/Public';
import CreateGroupDialog from './CreateGroupDialog';
import GroupInfoDialog from './GroupInfoDialog';
import { useDispatch, useSelector } from 'react-redux';
import { setUserGroups } from '../../reducers/user';
import { getUserGroups } from '../../utils/groupUtils';

const GroupTabs = () => {
  const { groups, activeGroupId, switchGroup } = useGroups();
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.token);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (groups) {
      setLoading(false);
    }
  }, [groups]);

  const handleChange = (event, newValue) => {
    console.log("Tab changed to:", newValue, "Active:", activeGroupId);
    
    // If clicking any group tab (not global or create)
    if (newValue !== 'global' && newValue !== 'create') {
      const group = groups.find(g => g._id === newValue);
      if (group) {
        // If it's the already selected tab, show info dialog
        if (newValue === activeGroupId) {
          setSelectedGroup(group);
          setInfoDialogOpen(true);
          return;
        }
        // Otherwise switch to the new group
        window.dispatchEvent(new CustomEvent('resetFeed'));
        switchGroup(newValue);
      }
      return;
    }

    if (newValue === 'create') {
      setCreateDialogOpen(true);
      return;
    }

    // Handle global tab
    window.dispatchEvent(new CustomEvent('resetFeed'));
    switchGroup(newValue);
  };

  const handleCreateSuccess = async (newGroup) => {
    setCreateDialogOpen(false);
    setSelectedGroup(newGroup);
    setInfoDialogOpen(true);
    
    // Fetch updated groups list
    try {
      const updatedGroups = await getUserGroups(token);
      dispatch(setUserGroups(updatedGroups));
    } catch (error) {
      console.error('Error fetching updated groups:', error);
    }
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
    if (selectedGroup) {
      window.dispatchEvent(new CustomEvent('resetFeed'));
      switchGroup(selectedGroup._id);
    }
  };

  //console.log("Current groups in GroupTabs:", groups);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '480px', margin: '0 auto' }}>
        <Tabs
          value={activeGroupId || 'global'}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="group tabs"
          sx={{ 
            opacity: loading ? 0.5 : 1,
            minHeight: '50px',
            '& .MuiTab-root': {
              minHeight: '50px',
              minWidth: '50px',
              padding: '6px 12px',
              color: '#666',
              borderRight: '1px solid #eee',
              textTransform: 'none',
              '&.Mui-selected': {
                color: '#5a9254',
                backgroundColor: 'white',
              },
              '&:not(.Mui-selected)': {
                opacity: 0.7,
                backgroundColor: '#f5f5f5',
              }
            }
          }}
        >
          <Tab 
            icon={<PublicIcon />} 
            iconPosition="start"
            label="" 
            value="global" 
          />
          
          {groups.map((group) => (
            <Tab
              key={group._id}
              label={group.name}
              value={group._id}
              onClick={(e) => {
                if (group._id === activeGroupId) {
                  setSelectedGroup(group);
                  setInfoDialogOpen(true);
                }
              }}
            />
          ))}

          <Tab
            icon={<AddIcon />}
            aria-label="create group"
            iconPosition="start"
            label="New Group"
            value="create"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              margin: 0,
              '& .MuiTab-iconWrapper': {
                margin: 0,
                marginRight: '0px'
              }
            }}
          />
        </Tabs>
      </Box>
      <CreateGroupDialog 
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreateSuccess={handleCreateSuccess}
      />
      <GroupInfoDialog 
        open={infoDialogOpen}
        onClose={handleInfoDialogClose}
        group={selectedGroup}
        isAdmin={true}
      />
    </>
  );
};

export default GroupTabs; 
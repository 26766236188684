import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { backendDomain } from '../constants';
import Navbar from '../components/Navbar';
import { useSelector } from 'react-redux';
import './css/LeaderboardPage.css';
import StaticNavHeader from '../components/StaticNavHeader';
import GroupTabsWrapper from '../components/groups/GroupTabsWrapper';
import GroupEmptyState from '../components/groups/GroupEmptyState';
import { fetchWithAuth } from '../utils/apiUtils';

const LeaderboardTable = ({ data, type }) => {
    const currentUser = useSelector((state) => state.user);
    const currentUserId = currentUser._id;

    if (!data || data.length === 0) {
        return <GroupEmptyState type="leaderboard" />;
    }

    return (
        <div className="leaderboard-table">
            <div className="table-header">
                <div className="rank-column">Rank</div>
                <div className="name-column">Name</div>
                <div className="streak-column">
                    {type === 'battle' ? 'Last 2d' : '🔥'}
                </div>
            </div>
            <div className="table-body">
                {data.map((user, index) => (
                    <div 
                        key={user._id} 
                        className={`table-row ${user._id === currentUserId ? 'current-user' : ''}`}
                        onClick={() => window.location.href = `/profile/${user._id}`}
                    >
                        <div className="rank-column">{index + 1}</div>
                        <div className="name-column">
                            <img src={user.imageUri} alt={`${user.firstName} ${user.lastName}`} className="user-avatar" />
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                        <div className="streak-column">
                            {type === 'battle' ? user.battleScore : 
                             type === 'current' ? user.currentStreak : user.maxStreak}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LeaderboardPage = () => {
    const [tabValue, setTabValue] = useState(0);
    const [battleScoreData, setBattleScoreData] = useState([]);
    const [currentStreakData, setCurrentStreakData] = useState([]);
    const [maxStreakData, setMaxStreakData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const user = useSelector((state) => state.user);
    const activeGroupId = useSelector((state) => state.user.activeGroupId);
    const isGlobal = !activeGroupId || activeGroupId === 'global';
    
    useEffect(() => {
        const handleReset = () => {
            setCurrentStreakData([]);
            setMaxStreakData([]);
            setLoading(true);
        };

        window.addEventListener('resetFeed', handleReset);
        return () => window.removeEventListener('resetFeed', handleReset);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log('Fetching leaderboard data for groupId:', activeGroupId, 'isGlobal:', isGlobal);
            setLoading(true);
            setError(null);
            
            try {
                if (isGlobal) {
                    const [currentResponse, maxResponse, battleResponse] = await Promise.all([
                        fetch(`${backendDomain}/wordle/leaderboard/currentstreak`),
                        fetch(`${backendDomain}/wordle/leaderboard/maxstreak`),
                        fetch(`${backendDomain}/wordle/leaderboard/battlescore`)
                    ]);

                    const [currentData, maxData, battleData] = await Promise.all([
                        currentResponse.json(),
                        maxResponse.json(),
                        battleResponse.json()
                    ]);
                    
                    if (Array.isArray(currentData) && Array.isArray(maxData) && Array.isArray(battleData)) {
                        setCurrentStreakData(currentData);
                        setMaxStreakData(maxData);
                        setBattleScoreData(battleData);
                    }
                } else {
                    const requestBody = { groupId: activeGroupId };
                    const options = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(requestBody)
                    };

                    const [currentResponse, maxResponse, battleResponse] = await Promise.all([
                        fetchWithAuth(`${backendDomain}/wordle/leaderboard/currentstreak-group`, options),
                        fetchWithAuth(`${backendDomain}/wordle/leaderboard/maxstreak-group`, options),
                        fetchWithAuth(`${backendDomain}/wordle/leaderboard/battlescore-group`, options)
                    ]);

                    const [currentData, maxData, battleData] = await Promise.all([
                        currentResponse.json(),
                        maxResponse.json(),
                        battleResponse.json()
                    ]);
                    
                    if (Array.isArray(currentData) && Array.isArray(maxData) && Array.isArray(battleData)) {
                        setCurrentStreakData(currentData);
                        setMaxStreakData(maxData);
                        setBattleScoreData(battleData);
                    } else {
                        throw new Error('Invalid response format from server');
                    }
                }
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
                setError('Failed to load leaderboard data');
                setCurrentStreakData([]);
                setMaxStreakData([]);
                setBattleScoreData([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [activeGroupId, isGlobal]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (loading) {
        return (
            <>
                <StaticNavHeader />
                <GroupTabsWrapper>
                    <div className="leaderboard-loading">Loading...</div>
                </GroupTabsWrapper>
                <Navbar />
            </>
        );
    }

    if (error) {
        return (
            <>
                <StaticNavHeader />
                <GroupTabsWrapper>
                    <div className="leaderboard-error">{error}</div>
                </GroupTabsWrapper>
                <Navbar />
            </>
        );
    }

    return (
        <>
            <StaticNavHeader />
            <GroupTabsWrapper>
                <div className="leaderboard-page">
                    <div className="tabs-container">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs 
                                value={tabValue} 
                                onChange={handleTabChange} 
                                centered
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#1976d2'
                                    },
                                    '& .MuiTab-root': {
                                        '&.Mui-selected': {
                                            color: '#1976d2',
                                            backgroundColor: 'transparent'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        },
                                        textTransform: 'none'
                                    }
                                }}
                            >
                                <Tab label="⚔️ Wins" />
                                <Tab label="Current Streak" />
                                <Tab label="Max Streak" />
                            </Tabs>
                        </Box>
                    </div>
                    <div className="table-container">
                        <Box sx={{ p: '12px 0' }}>
                            {tabValue === 0 && (
                                <LeaderboardTable data={battleScoreData} type="battle" />
                            )}
                            {tabValue === 1 && (
                                <LeaderboardTable data={currentStreakData} type="current" />
                            )}
                            {tabValue === 2 && (
                                <LeaderboardTable data={maxStreakData} type="max" />
                            )}
                        </Box>
                    </div>
                </div>
            </GroupTabsWrapper>
            <Navbar />
        </>
    );
};

export default LeaderboardPage; 
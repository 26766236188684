/**
 * Custom hook for managing the Wordle game state.
 * 
 * @param {string} solution - The correct word to guess.
 * @param {Array<string>} existingGuesses - Previous guesses made by the user.
 * @param {string} gameId - Unique identifier for the game.
 * @param {Object} user - User information, including emailId.
 * @param {string} message - Additional message to send with the game update.
 * @param {Object} initialUsedKeys - Initial used keys state from localStorage.
 * 
 * @returns {Object} - Contains game state and functions to handle user input.
 */

import { useState, useEffect, useCallback, useRef } from 'react';
import { backendDomain } from '../constants';
import { battleService } from './battleService';
import { fetchWithAuth } from '../utils/apiUtils';

const useWordle = (
  solution,
  existingGuesses = [],
  formattedGuesses,
  gameId,
  user,
  message,
  initialUsedKeys = {},
  gameMode = "dailyWordle",
  battleDayWordleSolution = ''
) => {
  const maxTurns = gameMode === 'battle' ? 4 : 6;
  const [turn, setTurn] = useState(existingGuesses.length);
  const [currentGuess, setCurrentGuess] = useState('');
  const [guesses, setGuesses] = useState(formattedGuesses);
  const [history, setHistory] = useState(existingGuesses);
  const [isCorrect, setIsCorrect] = useState(existingGuesses[existingGuesses.length - 1] === solution);
  const [usedKeys, setUsedKeys] = useState(initialUsedKeys);
  const [showModal, setShowModal] = useState(false);
  const [finalSolution, setFinalSolution] = useState(solution);
  const modalShownOnceRef = useRef(false); // Ref to track modal display
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update the guesses, history, and turn based on existingGuesses and solution
  useEffect(() => {
    setGuesses([...Array(maxTurns)].map((_, i) => formattedGuesses[i]));
    setHistory(existingGuesses);
    setTurn(existingGuesses.length);
    
    // Recalculate usedKeys based on formatted guesses
    const newUsedKeys = { ...initialUsedKeys };
    formattedGuesses?.forEach(guess => {
      if (guess) {
        guess.forEach(letter => {
          const currentColor = newUsedKeys[letter.key];
          if (letter.color === 'green') {
            newUsedKeys[letter.key] = 'green';
          } else if (letter.color === 'yellow' && currentColor !== 'green') {
            newUsedKeys[letter.key] = 'yellow';
          } else if (letter.color === 'grey' && currentColor !== 'green' && currentColor !== 'yellow') {
            newUsedKeys[letter.key] = 'grey';
          }
        });
      }
    });
    setUsedKeys(newUsedKeys);
  }, [existingGuesses, solution, maxTurns, formattedGuesses]);

  useEffect(() => {
    setIsCorrect(existingGuesses.length > 0 ? existingGuesses[existingGuesses.length - 1] === solution : false);
  }, [existingGuesses, solution]);

  // Function to add a new guess to the game state
  const addNewGuess = async (formattedGuess) => {
    // Check for game end conditions
    if (solution !== "" && currentGuess === solution) {
      setIsCorrect(true);
    }
    if (
      (gameMode === 'battle' && turn >= 4) ||
      (gameMode !== 'battle' && turn >= 6)
    ) {
      setIsCorrect(false);
    }

    setGuesses((prevGuesses) => {
      const newGuesses = [...prevGuesses];
      newGuesses[turn] = formattedGuess;
      return newGuesses;
    });

    setHistory((prevHistory) => [...prevHistory, currentGuess]);
    setTurn((prevTurn) => prevTurn + 1);

    // Update usedKeys on keyboard
    setUsedKeys((prevUsedKeys) => {
      const newUsedKeys = { ...prevUsedKeys };
      formattedGuess.forEach((l) => {
        const currentColor = newUsedKeys[l.key];
        if (l.color === 'green') {
          newUsedKeys[l.key] = 'green';
        } else if (l.color === 'yellow' && currentColor !== 'green') {
          newUsedKeys[l.key] = 'yellow';
        } else if (l.color === 'grey' && currentColor !== 'green' && currentColor !== 'yellow') {
          newUsedKeys[l.key] = 'grey';
        }
      });
      return newUsedKeys;
    });

    setCurrentGuess('');
  };

  const submitGuess = async () => {
    if (isSubmitting) return null;

    try {
      const reqBody = { wordleId: gameId, guess: currentGuess };
      console.log("Submitting Wordle guess:", reqBody);
      
      const response = await fetchWithAuth(`${backendDomain}/wordle/submitGuess`, {
        method: 'POST',
        body: JSON.stringify(reqBody),
      });
      
      const data = await response.json();
      
      if (data.status === 403) {
        alert('Invalid word!');
        return null;
      } else if (data.status !== 200) {
        alert('Error submitting guess. Please try again.');
        return null;
      }
      
      console.log("Wordle game updated successfully by server", data);
      return data;
    } catch (error) {
      console.error("Error submitting guess:", error);
      alert('Error submitting guess. Please try again.');
      return null;
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle keyboard input for user guesses
  const handleKeyup = ({ key }) => {
    if (isSubmitting){
      console.log("Already submitting, ignoring key ", key );
      return; // Prevent input during submission
    }; 

    if (key === 'Enter') {
      if (turn > maxTurns - 1) {
        console.log('You used all your guesses!');
        setIsSubmitting(false);
        return;
      }
      if (history.includes(currentGuess)) {
        alert('You already tried that word.');
        setIsSubmitting(false);
        return;
      }
      if (currentGuess.length !== 5) {
        alert('Too short.');
        setIsSubmitting(false);
        return;
      }


      setIsSubmitting(true);
      validateGuess(currentGuess).then(isValid => {
        if (!isValid) {
          alert('Invalid word!');
          setIsSubmitting(false);
          return;
        }

        // Use different submit function based on game mode
        if (gameMode === 'battle') {
          battleService.submitGuess(gameId, currentGuess)
            .then((response) => {
              console.log("Battle guess submitted successfully");
              const formatted = response.formattedGuess;
              addNewGuess(formatted);

              if (response.battleStatus === "completed") {
                setIsCorrect(response.result === "challenger_won");
                setFinalSolution(response.solution);
                scheduleModal();
              }
            })
            .catch(error => {
              console.error("Error submitting battle guess:", error);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        } else {
            submitGuess()
              .then((response) => {
                if (response) {
                  addNewGuess(response.formattedGuess);

                  if (response.result === "won" || response.result === "lost") {
                    setIsCorrect(response.result === "won");
                    setFinalSolution(response.solution);
                    scheduleModal();
                  }
                }
              })
            .catch(error => {
              console.error("Error updating Wordle game by server:", error);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      });
    }
    if (key === 'Backspace') {
      setCurrentGuess((prev) => prev.slice(0, -1));
      return;
    }
    if (/^[A-Za-z]$/.test(key)) {
      if (currentGuess.length < 5) {
        setCurrentGuess((prev) => prev + key);
      }
    }
  };

  // Function to schedule modal display after a delay
  const scheduleModal = useCallback(() => {
    if (modalShownOnceRef.current) return; // Prevent multiple schedules
    modalShownOnceRef.current = true;
    setTimeout(() => {
      setShowModal(true);
    }, 1700); // 1.5-second delay
  }, []);

  // Function to reset usedKeys state and remove from localStorage
  const resetUsedKeys = useCallback(() => {
    setUsedKeys({});
    localStorage.removeItem('usedKeys');
  }, []);

/*
  // Effect to reset usedKeys when a new game starts (gameId changes)
  useEffect(() => {
    if (gameMode !== 'battle' && gameId) {
      const storedGameId = localStorage.getItem('currentGameId');
      if (storedGameId !== gameId) {
        resetUsedKeys();
        localStorage.setItem('currentGameId', gameId);
      }
    }
  }, [gameId, resetUsedKeys]);
*/
  // Effect to update localStorage whenever usedKeys changes
  useEffect(() => {
    localStorage.setItem('usedKeys', JSON.stringify(usedKeys));
  }, [usedKeys]);

  return {
    turn,
    currentGuess,
    guesses,
    isCorrect,
    usedKeys,
    handleKeyup,
    resetUsedKeys,
    finalSolution,
    showModal,
    setShowModal,
    isSubmitting
  };
};

const validateGuess = async (guess) => {
  try {
    const response = await fetch(`${backendDomain}/wordle/validate-guess`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ guess: guess.toLowerCase() }),
    });
    const data = await response.json();
    console.log("Backend response:", response.status, data);
    if (response.status !== 200) {
      throw new Error(`Failed to validate guess: ${data.message}`);
    }
    return data.validGuess;
  } catch (error) {
    console.error("Error validating guess:", error);
    return false; // Return false on error to prevent undefined behavior
  }
}

export default useWordle;

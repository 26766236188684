import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { backendDomain } from '../constants';

// Async thunk to save user data to backend and update the Redux store
export const saveUser = createAsyncThunk(
    'user/saveUser',
    async (userData, { rejectWithValue, fulfillWithValue }) => {
        try {
            if (!userData) {
                return fulfillWithValue(null);
            }
            return userData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// User slice
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        _id: undefined,
        isAdmin: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        imageUri: undefined,
        likes: undefined,
        visitors: undefined,
        token: undefined,
        status: 'idle',
        error: null,
        groups: [],
        activeGroupId: null,
    },
    reducers: {
        clearUser: (state) => {
            Object.keys(state).forEach(key => {
                state[key] = undefined;
            });
            state.status = 'idle';
            state.error = null;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserGroups: (state, action) => {
            state.groups = action.payload;
            console.log("Updated groups in state:", state.groups);
        },
        setActiveGroup: (state, action) => {
            state.activeGroupId = action.payload;
            console.log("Updated activeGroupId in state:", state.activeGroupId);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { token, ...userData } = action.payload || {};
                const currentGroups = state.groups;
                Object.assign(state, userData);
                state.groups = currentGroups;
                if (token) state.token = token;
            })
            .addCase(saveUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

// Single export of all actions
export const { clearUser, setToken, setUserGroups, setActiveGroup } = userSlice.actions;
export default userSlice.reducer;

import React, { useState, useEffect } from "react";
import PostOverview from "./PostOverview";
import { useNavigate } from "react-router-dom";
import './css/FeedComponent.css';
import { backendDomain } from "../constants";
import { isSameDay, parseISO } from 'date-fns'; // Add this import
import { fetchWithAuth } from '../utils/apiUtils';

// Main component for displaying a feed of wordles
const FeedComponent = ({ user, groupId, isProfilePage = false }) => {
    const isGlobal = !groupId;  // Simplified logic
    // State variables
    const [wordles, setWordles] = useState([]); // Array to store wordles
    const [alreadyLoaded, setAlreadyLoaded] = useState(0); // Counter for loaded wordles
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();
    const [hasMorePages, setHasMorePages] = useState(true); // Pagination control
    const [users, setUsers] = useState({}); // Object to store users
    const [showPlayWordlePrompt, setShowPlayWordlePrompt] = useState(false);

    // Load wordles when component mounts or when feed type changes
    useEffect(() => {
        console.log("Feed type changed:", { groupId, isGlobal, isProfilePage });
        const resetAndLoad = async () => {
            setWordles([]); // Clear existing wordles
            setAlreadyLoaded(0); // Reset the counter
            setHasMorePages(true); // Reset pagination
            // Wait for state updates to complete
            await new Promise(resolve => setTimeout(resolve, 0));
            loadWordles();
        };
        
        resetAndLoad();
    }, [groupId]); // Only depend on groupId changes

    // Fetch user data when wordles are loaded
    useEffect(() => {
        if (wordles.length > 0) {
            fetchUsers();
        }
    }, [wordles]);

    // Handle infinite scrolling to load more wordles
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50) { // Adjust threshold as needed
                if (!loading && hasMorePages) {
                    loadWordles();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMorePages]);

    // Function to load wordles from the backend
    const loadWordles = async () => {
        if (loading) return;
        setLoading(true);
        try {
            let apiUrl;
            let requestBody;
            let options = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
            };

            console.log("Loading Wordles:", { groupId, isGlobal, isProfilePage, alreadyLoaded });

            if (isProfilePage) {
                apiUrl = `${backendDomain}/wordle/load-wordles-for-user`;
                requestBody = { userId: user._id, alreadyLoaded };
            } else if (isGlobal || (groupId && groupId === 'global')) {
                apiUrl = `${backendDomain}/wordle/load-wordles`;
                requestBody = { alreadyLoaded };
            } else if (groupId && groupId !== 'global') {
                apiUrl = `${backendDomain}/wordle/load-wordles-for-group`;
                requestBody = { groupId, alreadyLoaded };
                const response = await fetchWithAuth(apiUrl, {
                    ...options,
                    body: JSON.stringify(requestBody)
                });
                const data = await response.json();
                handleResponse(data);
                return;
            }

            const response = await fetch(apiUrl, {
                ...options,
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            handleResponse(data);

        } catch (error) {
            setError(error.message || "An error occurred while fetching wordles.");
        } finally {
            setLoading(false);
        }
    };

    // Helper function to handle response
    const handleResponse = (data) => {
        if (data.status === 200 && data.wordles && Array.isArray(data.wordles)) {
            setWordles(prevWordles => [...prevWordles, ...data.wordles]);
            setAlreadyLoaded(prev => prev + data.wordles.length);
            if (data.wordles.length < 10) {
                setHasMorePages(false);
            }
        } else {
            setError("Failed to load wordles.");
        }
    };

    // Function to fetch user data based on wordles
    const fetchUsers = async () => {
        const userIds = wordles.map(wordle => wordle.userId);
        const uniqueUserIds = [...new Set(userIds)]; // Remove duplicates

        try {
            const usersData = await Promise.all(uniqueUserIds.map(async (userId) => {
                const response = await fetch(`${backendDomain}/user/fetch-user`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id:userId }),
                });
                const data = await response.json();
                if (data.status === 200) {
                    return { [userId]: data.user };
                } else {
                    console.error(`Failed to fetch user data for userId: ${userId}`);
                    return null;
                }
            }));

            // Update state with users data
            const usersObject = usersData.reduce((acc, user) => ({ ...acc, ...user }), {});
            setUsers(prevUsers => ({ ...prevUsers, ...usersObject }));

        } catch (err) {
            setError("An error occurred while fetching user data.");
        }
    };

    // Function to determine if a post is the first of the day
    const isFirstPostOfDay = (currentPost, previousPost) => {
        if (!previousPost) return true;
        const currentDate = currentPost.createdAt.split('T')[0];
        const previousDate = previousPost.createdAt.split('T')[0];
        return currentDate !== previousDate;
    };

    const handleWordlePromptCancel = () => {
        setShowPlayWordlePrompt(false);
    };

    const handlePlayNowClick = () => {
        setShowPlayWordlePrompt(false);
        navigate('/wordle'); // Adjust this path if needed
    };

    // Add this effect near your other useEffect hooks
    useEffect(() => {
        const handleReset = () => {
            setWordles([]);
            setAlreadyLoaded(0);
            setHasMorePages(true);
        };

        window.addEventListener('resetFeed', handleReset);
        return () => window.removeEventListener('resetFeed', handleReset);
    }, []);

    // Render the component
    return (
        <div className={`feed-container ${isProfilePage ? 'profile-page' : ''}`}>
            {wordles.length === 0 && !loading && !error && (
                <p>No Posts Here</p>
            )}
            {error && <p>{error}</p>}
            {wordles.map((wordle, index) => {
                const userForWordle = users[wordle.userId];
                const isFirstOfDay = isFirstPostOfDay(wordle, wordles[index - 1]);
                return (
                    <React.Suspense fallback={<p>Loading post...</p>} key={index}>
                        {userForWordle ? (
                            <PostOverview
                                user={userForWordle}
                                wordle={wordle}
                                isFirstOfDay={isFirstOfDay}
                                isProfilePage={isProfilePage}
                            />
                        ) : (
                            <p>Loading user data...</p>
                        )}
                    </React.Suspense>
                );
            })}
            {loading && <p>Loading...</p>}
            {!hasMorePages && !loading && <p>That's it!</p>}
            {showPlayWordlePrompt && (
                    <div className="delete-dialog">
                        <h3>Good Afternoon</h3>
                        <p>Today's Wordle is Ready</p>
                        <div className="delete-dialog-buttons">
                            <button className="cancel-button" onClick={handleWordlePromptCancel}>Later</button>
                            <button className="play-now" onClick={handlePlayNowClick}>Play Now</button>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default FeedComponent;
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserGroups, setActiveGroup } from '../reducers/user';
import { getUserGroups } from '../utils/groupUtils';

export const useGroups = () => {
  const dispatch = useDispatch();
  const { groups, activeGroupId, token, user } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchGroups = async () => {
      if (token) {
        try {
          const fetchedGroups = await getUserGroups(token);
          console.log("Fetched Groups in useGroups.js: ", fetchedGroups);
          dispatch(setUserGroups(fetchedGroups));
        } catch (error) {
          console.error('Error fetching groups:', error);
        }
      }
    };

    fetchGroups();
  }, [dispatch, token, user]);

  const switchGroup = (groupId) => {
    dispatch(setActiveGroup(groupId));
  };

  return {
    groups,
    activeGroupId,
    switchGroup
  };
}; 
import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField,
  Box,
  IconButton,
  Typography,
  Snackbar
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useSelector } from 'react-redux';

const GroupInfoDialog = ({ open, onClose, group, isAdmin }) => {
  const [groupName, setGroupName] = useState(group?.name || '');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const inviteLink = `${window.location.origin}/group/${group?.inviteCode}`;

  useEffect(() => {
    if (group?.name) {
      setGroupName(group.name);
    }
  }, [group]);

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setShowSnackbar(true);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Join my Social Wordle group: ${groupName}`,
          text: 'Join my Social Wordle group!',
          url: inviteLink
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      handleCopy();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Group Info</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Group Name"
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            disabled={!isAdmin}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" gutterBottom>
            Invite friends to your group via link:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            bgcolor: 'grey.100',
            p: 2,
            borderRadius: 1
          }}>
            <Typography 
              variant="body2" 
              sx={{ 
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {inviteLink}
            </Typography>
            <IconButton onClick={handleCopy} size="small">
              <ContentCopyIcon />
            </IconButton>
            <IconButton onClick={handleShare} size="small">
              <ShareIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message="Link copied to clipboard"
      />
    </>
  );
};

export default GroupInfoDialog; 
import { backendDomain } from '../constants';
import { fetchWithAuth } from './apiUtils';

export const createGroup = async (name, token) => {
  const response = await fetchWithAuth(
    `${backendDomain}/group/create`,
    {
      method: 'POST',
      body: JSON.stringify({ name })
    },
    token
  );

  if (!response.ok) {
    throw new Error('Failed to create group');
  }

  return response.json();
};

export const getUserGroups = async (token) => {
  console.log("Fetching list of groups for user");
  const response = await fetchWithAuth(
    `${backendDomain}/group/list`,
    {
      method: 'GET'
    },
    token
  );

  if (!response.ok) {
    throw new Error('Failed to fetch groups');
  }

  const groups = await response.json();
  
  // Log the groups to verify the inviteCode is present
  console.log("Fetched groups:", groups);
  
  return groups;
};

export const joinGroup = async (inviteCode, token) => {
  const response = await fetchWithAuth(
    `${backendDomain}/group/join/${inviteCode}`,
    {
      method: 'POST'
    },
    token
  );
  
  if (!response.ok) {
    throw new Error('Failed to join group');
  }
  
  return response.json();
};

export const getGroupByInviteCode = async (inviteCode, token) => {
  const response = await fetchWithAuth(
    `${backendDomain}/group/invite/${inviteCode}`,
    {
      method: 'GET'
    },
    token
  );
  
  if (!response.ok) {
    throw new Error('Failed to fetch group information');
  }
  
  return response.json();
}; 
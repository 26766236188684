import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveUser, setToken } from '../reducers/user';
import { backendDomain } from '../constants';

const AuthCallback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                console.log("Handling auth callback");
                // Get token from URL params
                const params = new URLSearchParams(window.location.search);
                const token = params.get('token');
                const error = params.get('error');

                if (error) {
                    throw new Error(error);
                }

                if (!token) {
                    throw new Error('No token received');
                }
                console.log("Token received");
                dispatch(setToken(token));
                localStorage.setItem('token', token);

                // Fetch user details using the token
                const response = await fetch(`${backendDomain}/user/fetch-logged-in-user`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({})
                });

                const data = await response.json();
                console.log("User data received", data);
                
                if (!response.ok || data.status !== 200) {
                    throw new Error(data.message || 'Failed to fetch user details');
                }

                // Store user data
                localStorage.setItem('user', JSON.stringify(data.user));
                dispatch(saveUser({ ...data.user, token }));

                // Check for pending group invite
                const pendingInvite = sessionStorage.getItem('pendingGroupInvite');
                if (pendingInvite) {
                    sessionStorage.removeItem('pendingGroupInvite');
                    navigate(`/group/${pendingInvite}`);
                    return;
                }

                // Clear URL parameters and redirect
                window.history.replaceState({}, document.title, '/');
                navigate('/', { replace: true });
            } catch (error) {
                console.error('Auth callback error:', error);
                navigate('/profile?error=auth_failed', { replace: true });
            }
        };

        handleCallback();
    }, [dispatch, navigate]);

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
            fontFamily: 'Arial, sans-serif',
            color: '#666'
        }}>
            <div>
                <div style={{ marginBottom: '10px' }}>Processing login...</div>
                <div style={{ fontSize: '0.8em' }}>Please wait while we complete your authentication</div>
            </div>
        </div>
    );
};

export default AuthCallback;
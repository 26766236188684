import React from 'react';
import { Box } from '@mui/material';
import GroupTabs from './GroupTabs';
import { useGroups } from '../../hooks/useGroups';

const GroupTabsWrapper = ({ children }) => {
  const { activeGroupId } = useGroups();

  // Clone the child component and pass the activeGroupId prop
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { groupId: activeGroupId });
    }
    return child;
  });

  return (
    <Box>
      <GroupTabs />
      {childrenWithProps}
    </Box>
  );
};

export default GroupTabsWrapper; 